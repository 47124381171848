@font-face {
  font-family: 'NimbusSansNovusT';
  src: url('../src/style/NimbusSansNovusT-UltraLight.ttf') format('truetype');
  /* You can also specify other font properties like font-weight and font-style here */
}

body, h1, h2, h3, h4, h5, h6, p, ul, ol, li {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'NimbusSansNovusT', sans-serif;
}

input::placeholder {
  font-family: 'NimbusSansNovusT', sans-serif;
	color: white;
}

textarea::placeholder {
	font-family: 'NimbusSansNovusT', sans-serif;
	/* color: white; */
}